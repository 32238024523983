import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"

// This is a 'polyfill' for browser API due to interaction with Node during build process.
// https://deliciousreverie.co.uk/post/deserializing-data-in-gatsbyjs/
import { DOMParser } from "xmldom"

import { Card } from "../../modules/card/card"
import { SurveyMonkeyEmbed } from "../../modules/survey-monkey-embed/survey-monkey-embed"
import { YouTubeEmbed } from "../../modules/youtube-embed/youtube-embed"

export const CardGrid = ({ body }) => {
  const { wagtail } = useStaticQuery(
    graphql`
      query {
        wagtail {
          images {
            id
            src
            imageFile {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `
  )

  const getNumberFromCol = col => col.match(/\d+/)[0]

  const splitHtmlIntoSeparateElements = html => {
    // Remove surrounding HTML chevrons and split into array by opening/closing chevrons.
    let body = html.substring(1, html.length - 1).split(/></g)

    // Add the chevrons back that were removed by the split.
    for (let i = 0; i < body.length; i++) {
      body[i] = "<" + body[i] + ">"
    }

    let htmlAroundEmbed = ""
    const htmlWithGatsbyImg = []

    body.forEach((element, i) => {
      const isFinalElement = i === body.length - 1
      const isEmbedImgElement = element.includes("<embed")

      if (!isEmbedImgElement) {
        htmlAroundEmbed += element
      }

      if (isFinalElement || isEmbedImgElement) {
        htmlWithGatsbyImg.push(htmlAroundEmbed)

        if (isEmbedImgElement) {
          const embed = new DOMParser()
            .parseFromString(element, "text/html")
            .getElementsByTagName("embed")[0]
          const id = embed.getAttribute("id")
          const format = embed.getAttribute("format")

          const image = wagtail.images.find(image => image.id === id).imageFile
          if (image) {
            htmlWithGatsbyImg.push(
              <Img
                key={id}
                fixed={image.childImageSharp.fixed}
                className={`align-${format}`}
              />
            )
          }

          htmlAroundEmbed = ""
        }
      }
    })

    return htmlWithGatsbyImg
  }

  const findField = (blocks, field, valueKey) =>
    blocks.find(block => block.field === field)[valueKey]

  return (
    <div className="row">
      {body.map(({ blocks }, i) => {
        const width = blocks[0].rawValue
        const cards = blocks[1].blocks

        return (
          <div key={i} className={`col-12 col-lg-${getNumberFromCol(width)}`}>
            <div className="row d-flex h-100">
              {cards.map(({ field, blocks }, i) => {
                // Hacky nonsense b/c of wagtail stupidity.
                const isYouTube = field === "youtube"
                const isSurvey = field === "survey_monkey"

                const width = findField(blocks, "width", "value")

                let card = undefined

                if (!isYouTube) {
                  const header = findField(blocks, "header", "value")
                  const subheader = findField(blocks, "subheader", "value")
                  const body = findField(blocks, "body", "rawValue")
                  const hasBorder =
                    findField(blocks, "border", "rawValue") === "true"

                  let hasButton = false
                  if (!isSurvey) {
                    hasButton =
                      findField(blocks, "has_button", "rawValue") === "true"
                  }

                  card = (
                    <Card
                      title={header}
                      subtitle={subheader}
                      callToAction={
                        hasButton && {
                          ariaLabel: findField(
                            blocks,
                            "button_aria_label",
                            "value"
                          ),
                          label: findField(blocks, "button_text", "value"),
                          href: findField(blocks, "button_hyperlink", "value"),
                          classNames: "chevron-icon",
                        }
                      }
                      palette={hasBorder ? "green-border" : "default"}
                    >
                      {splitHtmlIntoSeparateElements(
                        body
                          .replace(/<b>/g, "<strong>")
                          .replace(/<\/b>/g, "</strong>")
                      ).map(el =>
                        el && typeof el !== "string" ? (
                          <div key={el}>{el}</div>
                        ) : (
                          <div
                            key={el}
                            dangerouslySetInnerHTML={{ __html: el }}
                          />
                        )
                      )}

                      {isSurvey && (
                        <SurveyMonkeyEmbed
                          src={findField(blocks, "survey_link", "value")}
                        />
                      )}
                    </Card>
                  )
                } else {
                  const link = findField(blocks, "link", "value")
                  card = <YouTubeEmbed src={link} />
                }

                return (
                  <div
                    key={i}
                    className={`
                      col-md-${getNumberFromCol(width)} 
                      col-12 
                      mb-4 
                      d-flex
                    `}
                  >
                    {card}
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

CardGrid.propTypes = {
  body: PropTypes.array,
}
