import React, { useEffect } from "react"

import PropTypes from "prop-types"

import "./survey-monkey-embed.scss"

export const SurveyMonkeyEmbed = ({ src }) => {
  useEffect(() => {
    const el = document.createElement("script")
    el.src = src
    document.body.appendChild(el)
  }, [])

  return (
    <div className="survey-monkey-embed">
      <div id="smcx-sdk"></div>
    </div>
  )
}

SurveyMonkeyEmbed.propTypes = {
  src: PropTypes.string,
}
