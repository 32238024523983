import React from "react"

import PropTypes from "prop-types"

import "./youtube-embed.scss"

export const YouTubeEmbed = ({ src }) => (
  <iframe
    className="youtube-embed w-100 h-100"
    src={src}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
)

YouTubeEmbed.propTypes = {
  src: PropTypes.string,
}
