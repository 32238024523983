import React from "react"

import PropTypes from "prop-types"
import Img from "gatsby-image"

import "./card.scss"

export const Card = ({
  title,
  titleIcon,
  subtitle,
  palette,
  callToAction,
  children,
}) => (
  <div className={`card ${palette}`}>
    <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
      <div className="card-body-content">
        {title && (
          <h2
            className={`
              h4 
              ${subtitle ? "mb-2" : "mb-3"} 
              ${titleIcon ? "d-inline-block" : ""}
            `}
          >
            {title}
          </h2>
        )}

        {titleIcon && (
          <Img
            fluid={titleIcon}
            className="ml-3 mb-3 float-right card-header-img"
          />
        )}

        {subtitle && <h3 className="h5 text-dark mb-3">{subtitle}</h3>}

        {children}
      </div>

      {callToAction && (
        <a
          className={`btn btn-primary w-100 py-1 ${callToAction.classNames}`}
          href={callToAction.href}
          aria-label={callToAction.ariaLabel}
        >
          {callToAction.label}
        </a>
      )}
    </div>
  </div>
)

Card.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  palette: PropTypes.oneOf(["default", "green-border"]),
  callToAction: PropTypes.oneOfType([
    PropTypes.shape({
      href: PropTypes.string,
      classNames: PropTypes.string,
      ariaLabel: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
}
